import React from "react";
import { Box, createTheme, keyframes, ThemeProvider } from "@mui/system";

import logo from "./assets/intro.jpg";
import avatar from "./assets/avatar.png";

const customTheme = createTheme({
  palette: {
    p: {
      green: "#1a535c",
      turquoise: "#4ecdc4",
      cream: "#f7fff7",
      bittersweet: "#ff6b6b",
      crayola: "#ffe66d",
    },
    s: {
      fogra: "#001524",
      ming: "#15616d",
      almond: "#ffecd1",
      amber: "#ff7d00",
      kobe: "#78290f",
    },
  },
});

const contactLines = [
  "HardCoded ApS",
  "Boghvedevej 7",
  "8800 Viborg",
  ["linkedin", "https://www.linkedin.com/in/anders-morten-mikkelsen-97485b1/"],
  "cvr: 3888 9427",
];

const animation = keyframes`
  0%: {
    boxShadow: "0 5px 15px 0px rgba(0,0,0,0.6)",
    transform: "translatey(0px)"
  }
  50% {
    box-shadow: 0 25px 15px 0px rgba(0,0,0,0.2);
    transform: translatey(-20px);
  }
  100% {
    box-shadow: 0 5px 15px 0px rgba(0,0,0,0.6);
    transform: translatey(0px);
  }
`;

const isArray = (l: any) => !!l.splice;

const ContactCard: React.FC = () => {
  return (
    <Box
      sx={{
        position: "absolute",
        padding: "0px",
        margin: {
          xs: "20px 20px 0 20px",
          sm: "unset",
        },
        background: (theme) =>
          `linear-gradient(135deg, ${theme.palette.p.turquoise} 0%, ${theme.palette.p.crayola} 100%)`,
        right: { sm: "50px" },
        bottom: {
          xs: "20px",
          sm: "50px",
        },
        width: {
          xs: "calc(100% - 40px)",
          sm: "unset",
        },
        border: 0,
        borderRadius: "3px",
        overflow: "hidden",
      }}
    >
      <Box
        sx={{
          margin: "10px",
          border: 3,
          borderColor: "p.green",
          borderRadius: "3px",
          display: "grid",
          gridTemplateColumns: {
            xs: "auto",
            sm: "auto auto",
          },
          alignItems: "center",
          justifyItems: "center",
        }}
      >
        <Box
          component="img"
          src={avatar}
          alt="avatar"
          sx={{
            height: "120px",
            borderRadius: "50%",
            boxSizing: "border-box",
            border: 3,
            borderColor: "p.green",
            marginTop: "30px",
            marginBottom: "20px",
            marginLeft: {
              sm: "30px",
            },
            boxShadow: "0 5px 15px 0px rgba(0,0,0,0.6)",
            transform: "translatey(0px)",
            animation: `${animation} 6s ease-in-out infinite`,
          }}
        ></Box>
        <Box
          sx={{
            margin: "30px",
            display: "flex",
            flexDirection: "column",
            alignItems: {
              xs: "center",
              sm: "end",
            },
          }}
        >
          {contactLines.map((l, ix) => (
            <Box
              component="span"
              key={ix}
              sx={{
                color: "p.green",
                marginTop: "5px",
                fontSize: "18px",
                "&:first-child": {
                  marginTop: "0",
                  marginBottom: "5px",
                  fontWeight: "bold",
                  fontSize: "25px",
                  borderBottom: "1px solid",
                  borderBottemColor: "p.green",
                },
              }}
            >
              {isArray(l) ? (
                <Box component="a" href={l[1]} sx={{ color: "p.green" }}>
                  {l[0]}
                </Box>
              ) : (
                l
              )}
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

function App() {
  return (
    <ThemeProvider theme={customTheme}>
      <Box
        sx={{
          position: "absolute",
          backgroundImage: `url(${logo})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "100% 100%",
          width: "100%",
          height: "100%",
          margin: 0,
          padding: 0,
          overflow: "hidden",
        }}
      >
        <ContactCard></ContactCard>
      </Box>
    </ThemeProvider>
  );
}

export default App;
